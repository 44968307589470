import { Box, Button } from "@mui/material";

const Playground = () => {
  return (
    <>
      <Box sx={{ height: '300px'}}>

      </Box>
      <Button variant="contained">Click me</Button>
    </>
  )
}

export default Playground;
