import { Box, styled } from '@mui/system';

const HeaderBox = styled(Box)({
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: '35% 75%',
  position: 'relative',  
});

const WaveBox = styled(Box)({
  position: 'absolute',
  bottom: '0',
  left: '0',
  width: '100%',
  overflow: 'hidden',
});

const Header = ({ minHeight }) => {
  return(
    <HeaderBox sx={{ minHeight: minHeight, maxWidth: '1920px', backgroundImage: `url(/images/headerPhotoPicture/Uvod_1.jpg)`, margin: '0 auto' }}>
        <WaveBox>
            <svg 
                data-name="Layer 1" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 1200 120" 
                preserveAspectRatio="none"
                style={{ position: 'relative', display: 'block', width: 'calc(191% + 1.3px)', height: '208px' }}
            >
                <path 
                    d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" 
                    className="shape-fill"
                    style={{ fill: '#FFFFFF' }}
                />
            </svg>
        </WaveBox>
    </HeaderBox>
    );
}

export default Header;